.loginDiv {
    width: 100vw;
    height: 100vh;
    background-color: #3374b9;
    /* background-color: '#3374B9' */
}

.avatar {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid white;
  }
  
    
@media all and (max-height: 680px) {
    .loginDiv {
      height: 100%;
      /* height: 680px; */
    }
}
  