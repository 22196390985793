* {
  padding: 0;
  margin: 0;
}

.blueDiv {
  width: 100vw;
  height: 100vh;
  background-color: #3374b9;
}
.blueDivShrinked {
  width: 100vw;
  height: 100vh;
  background-color: #3374b9;
}

.blueDivAnimation {
  animation: shrink 0.8s;
  animation-fill-mode: forwards;
}

@keyframes shrink {
  0% {
    width: 100vw;
  }
  100% {
    width: 22vw;
  }
}

.footerList {
  margin:1%;
  color: #ffffff;
}

.avatar {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid white;
}

@media all and (max-height: 725px) {
  .blueDivShrinked {
    /* height: 760px; */
  }
}


